<template>
	<footer id="footer">
		<div class="container">
			<div class="flex-container">
				<div class="footer-logo">
					<img src="@/assets/images/footer-logo.svg" />
				</div>

				<div class="footer-contents">
					<ul>
						<li>사업자등록번호 : 497-86-02579</li>
						<li>통신판매업 신고번호 : 2023-성남분당A-0909</li>
						<li>E-mail : Admin@creadto.com</li>
						<li>
							Address : 1810, C, 170 Seohyeon-ro, Bundang-gu, Seongnam-si,
							Gyeonggi-do, Republic of Korea
						</li>
						<li>Tel : 070-8657-4546</li>
					</ul>
					<p class="copyright">© 2022 Creadto, Inc.</p>
				</div>

				<div class="sns-wrap">
					<ul>
						<li>
							<a
								href="https://www.facebook.com/people/Creadto/100088063030571/"
								target="_blank"
							>
								<img
									src="@/assets/images/sns-facebook.svg"
									class="icon-large"
								/>
							</a>
						</li>
						<li>
							<a
								href="https://www.linkedin.com/company/creadto/"
								target="_blank"
							>
								<img
									src="@/assets/images/sns-linkedin.svg"
									class="icon-large"
								/>
							</a>
						</li>
						<li>
							<a href="#" target="_blank">
								<img src="@/assets/images/sns-twitter.svg" class="icon-large" />
							</a>
						</li>
						<li>
							<a href="https://github.com/Creadto" target="_blank">
								<img src="@/assets/images/sns-github.svg" class="icon-large" />
							</a>
						</li>
						<li>
							<a href="https://www.youtube.com/@creadto/" target="_blank">
								<img src="@/assets/images/sns-youtube.svg" class="icon-large" />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<el-popover
			placement="top"
			width="300px"
			v-model="tooltip_visible"
			class="tooltip"
		>
			<div class="tooltip-title">
				<h2>Contact Us</h2>
				<div class="icon-wrap" @click="tooltip_visible = false">
					<img src="@/assets/images/icon-close.svg" class="icon-large" />
				</div>
			</div>

			<form ref="form" @submit.prevent="sendEmail">
				<div class="form-item">
					<label id="name"
						>이름
						<span class="color-main">*</span>
					</label>
					<input type="text" v-model="name" name="name" />
				</div>

				<div class="form-item">
					<label id="email"
						>이메일 주소
						<span class="color-main">*</span>
					</label>
					<input type="email" v-model="email" name="email" />
				</div>

				<div class="form-item">
					<label id="inquiry-contents"
						>문의 사항
						<span class="color-main">*</span>
					</label>
					<textarea
						v-model="inquiry_contents"
						name="inquiry_contents"
					></textarea>
				</div>

				<button class="btn-main btn-medium btn-full">문의하기</button>
			</form>

			<div class="icon-talk" slot="reference">
				<img src="@/assets/images/icon-talk.svg" />
			</div>
		</el-popover>
	</footer>
</template>

<script>
import emailjs from '@emailjs/browser'

export default {
	data() {
		return {
			tooltip_visible: false,
			name: '',
			email: '',
			inquiry_contents: '',
		}
	},
	computed: {
		c_year() {
			return new Date().getFullYear()
		},
	},

	methods: {
		sendEmail() {
			emailjs
				.sendForm(
					process.env.VUE_APP_SERVICE_ID,
					process.env.VUE_APP_SIMPLE_TEMPLATE_ID,
					this.$refs.form,
					process.env.VUE_APP_PUBLIC_KEY
				)
				.then(
					result => {
						alert(
							'이메일 문의가 완료되었습니다. 2-3일 이내에 이메일로 연락드리겠습니다.'
						)
						console.log('SUCCESS!', result.text)
					},
					error => {
						console.log('FAILED...', error.text)
					}
				)
		},
	},
}
</script>

<style scoped>
#footer {
	padding: 70px 0;
	overflow: hidden;
	position: relative;
	margin-top: 50px;
	border-top: 1px solid #dde8f0;
	color: #555;
	background: #fff;
	bottom: 0;
	margin: 0 auto;
	width: 100%;
}

#footer .flex-container {
	display: flex;
	gap: 80px;
}

.footer-logo {
	float: left;
}

.footer-contents li {
	letter-spacing: 0;
}

.footer-contents .copyright {
	letter-spacing: 0;
	margin-top: 30px;
}

.sns-wrap {
	position: absolute;
	bottom: 0;
	right: 0;
}

.sns-wrap li {
	float: left;
	margin-right: 10px;
}

.icon-talk {
	position: fixed;
	right: 30px;
	bottom: 30px;
	width: 70px;
	height: 70px;
	cursor: pointer;
}

@media (max-width: 1023px) {
	.sns-wrap {
		right: 24px;
	}
}

@media (max-width: 767px) {
	#footer {
		padding: 50px 0;
	}

	#footer .flex-container {
		display: block;
	}

	.footer-logo {
		float: none;
		width: 100px;
		margin-bottom: 50px;
	}

	.sns-wrap {
		position: relative;
		padding: 50px 24px 0;
	}
}
</style>
