import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import i18n from './locales/i18n.js'
// import mitt from 'mitt'

// let emitter = mitt()
// let app = App
// app.config.globalProperties.emitter = emitter

Vue.use(ElementUI, { locale })

Vue.config.productionTip = false
console.log(Vue.version)
new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')
