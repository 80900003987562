<template>
	<header id="header">
		<div class="container">
			<div class="logo-wrap">
				<a href="/">
					<img alt="로고" src="../assets/images/logo-black.svg" class="logo" />
				</a>
			</div>
			<nav id="nav" class="pc">
				<div class="nav-item">
					<el-menu
						:default-active="active_index"
						class="el-menu-demo"
						mode="horizontal"
					>
						<el-submenu index="1">
							<template slot="title">Product</template>
							<el-menu-item index="1-1">
								<a href="https://www.gachicam.app" target='_blank'>가치캠</a>
								<!--<router-link to="/reverse">가치캠</router-link>-->
							</el-menu-item>
							<el-menu-item index="1-2">
								<a href="https://www.gachiga.app" target='_blank'>가치가</a>
								<!--<router-link to="/gachiga">가치가</router-link>-->
							</el-menu-item>
						</el-submenu>
						<el-menu-item index="2">
							<router-link to="/showcase">Showcase</router-link>
						</el-menu-item>
						<el-submenu index="3">
							<template slot="title">Solutions</template>
							<el-menu-item index="3-1">
								<router-link to="/solutions-vfs">VFS</router-link>
							</el-menu-item>
							<el-menu-item index="3-1">
								<router-link to="/solutions-other">Other</router-link>
							</el-menu-item>
						</el-submenu>
						<el-menu-item index="4">
							<router-link to="/pricing">Pricing</router-link>
						</el-menu-item>
						<el-submenu index="4">
							<template slot="title">Company</template>
							<el-menu-item index="4-1">
								<router-link to="/about">About</router-link>
							</el-menu-item>
							<el-menu-item index="4-2">
								<router-link to="/notice">Notice</router-link>
							</el-menu-item>
							<el-menu-item index="4-3">
								<router-link to="/careers">Careers</router-link>
							</el-menu-item>
							<el-menu-item index="4-4">
								<router-link to="/contact">Contact us</router-link>
							</el-menu-item>
						</el-submenu>
					</el-menu>
				</div>
			</nav>

			<div class="btn-wrap pc">
				<div class="lang-wrap">
					<el-dropdown
						@command="handleCommand"
						trigger="click"
						@visible-change="handleSelect"
					>
						<span class="el-dropdown-link">
							<div class="lang-image">
								<img src="@/assets/images/icon-globe.svg" class="icon-large" />

								<img
									src="@/assets/images/lang-kr.svg"
									class="icon-large"
									v-if="active_lang === 'kr'"
								/>
								<img
									src="@/assets/images/lang-us.svg"
									class="icon-large"
									v-if="active_lang === 'us'"
								/>
							</div>
							<div class="lang-text">
								<span> {{ active_lang }}</span>
								<i
									class="el-icon-arrow-down el-icon--right"
									v-if="active_select === false"
								></i>

								<i
									class="el-icon-arrow-up el-icon--right"
									v-if="active_select === true"
								></i>
							</div>
						</span>

						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="kr">
								<img src="@/assets/images/lang-kr.svg" class="icon-large" />
								<span>KR</span>
							</el-dropdown-item>
							<el-dropdown-item command="us">
								<img src="@/assets/images/lang-us.svg" class="icon-large" />
								<span>US</span>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>

				<button
					class="btn-medium btn-main btn-round"
					@click="$router.push('/contact')"
					alt="언어 아이콘"
				>
					Talk to Sales
				</button>
			</div>

			<div class="btn-menu tablet mobile" @click="drawer = true">
				<ul>
					<li></li>
					<li></li>
					<li></li>
				</ul>
			</div>

			<el-drawer
				:visible.sync="drawer"
				:with-header="false"
				:append-to-body="true"
				size="80%"
			>
				<div class="drawer-header">
					<div class="logo-wrap">
						<img src="@/assets/images/logo-black.svg" class="logo" />
					</div>
					<div class="icon-wrap">
						<img
							src="@/assets/images/icon-close.svg"
							class="icon-large"
							@click="handleClose"
						/>
					</div>
				</div>

				<div class="drawer-contents">
					<nav id="nav" class="tablet mobile">
						<ul>
							<li>
								<p>Product</p>
								<router-link to="/reverse">가치캠</router-link>
							</li>
							<li>
								<p>Product</p>
								<router-link to="/gachiga">가치가</router-link>
							</li>

							<li>
								<p>Showcase</p>
								<router-link to="/showcase">Showcase</router-link>
							</li>

							<li>
								<p>Solutions</p>
								<router-link to="/solutions-vfs">VFS</router-link>
								<router-link to="/solutions-other">Other</router-link>
							</li>

							<li>
								<p>Pricing</p>
								<router-link to="/pricing">Pricing</router-link>
							</li>

							<li>
								<p>Company</p>
								<router-link to="/about">About</router-link>
								<router-link to="/notice">Notice</router-link>
								<router-link to="/careers">Careers</router-link>
								<router-link to="/contact">Contact us</router-link>
							</li>
						</ul>
					</nav>

					<div class="lang-wrap">
						<el-dropdown
							@command="handleCommand"
							trigger="click"
							@visible-change="handleSelect"
						>
							<span class="el-dropdown-link">
								<div class="lang-image">
									<img
										src="@/assets/images/icon-globe.svg"
										class="icon-large"
									/>

									<img
										src="@/assets/images/lang-kr.svg"
										class="icon-large"
										v-if="active_lang === 'kr'"
									/>
									<img
										src="@/assets/images/lang-us.svg"
										class="icon-large"
										v-if="active_lang === 'us'"
									/>
								</div>
								<div class="lang-text">
									<span> {{ active_lang }}</span>
									<i
										class="el-icon-arrow-down el-icon--right"
										v-if="active_select === false"
									></i>

									<i
										class="el-icon-arrow-up el-icon--right"
										v-if="active_select === true"
									></i>
								</div>
							</span>

							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="kr">
									<img src="@/assets/images/lang-kr.svg" class="icon-large" />
									<span>KR</span>
								</el-dropdown-item>
								<el-dropdown-item command="us">
									<img src="@/assets/images/lang-us.svg" class="icon-large" />
									<span>US</span>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</el-drawer>
		</div>
	</header>
</template>

<script>
import { saveLocaleCookie } from '@/utils/cookies'

export default {
	data() {
		return {
			active_index: '1',
			drawer: false,
			active_lang: 'kr',
			active_select: false,
		}
	},
	watch: {
		$route(to, from) {
			if (to.path != from.path) {
				this.drawer = false
			}
		},
	},
	methods: {
		handleClose() {
			this.drawer = false
		},
		handleCommand(command) {
			this.active_lang = command
			this.$i18n.locale = command
			saveLocaleCookie(command)
		},
		handleSelect() {
			this.active_select = !this.active_select
		},
	},
}
</script>

<style scoped>
#header {
	overflow: hidden;
	position: fixed;
	width: 100%;
	z-index: 99;
	height: 80px;
	top: 0;
	background: #fff;
	border-bottom: 1px solid rgb(222, 225, 234);
}

.logo-wrap {
	float: left;
	padding: 20px 0;
	width: 170px;
}

#nav {
	float: left;
	margin-left: 30px;
}

#nav .nav-item {
	float: left;
	padding: 10px 0;
}

#nav .nav-item a {
	letter-spacing: 0;
}

.lang-wrap {
	margin: 18px 30px;
	display: inline-block;
	border: 1px solid #dee1ea;
	border-radius: 5px;
	height: 42px;
}

.lang-wrap .lang-image {
	float: left;
}

.lang-wrap .lang-image img {
	padding-right: 10px;
}

.lang-wrap .lang-text {
	float: right;
}

.lang-wrap li {
	float: left;
	margin-left: 10px;
	padding: 25px 0;
}

#nav button {
	float: right;
	margin: 18px 0;
}

.btn-menu {
	float: right;
	width: 24px;
	height: 24px;
	padding-top: 10px;
}

.btn-menu li {
	background: black;
	width: 24px;
	height: 2px;
	border-radius: 1px;
	margin-bottom: 5px;
}

.btn-menu li:last-child {
	margin: 0;
}

#nav.mobile {
	margin-left: 0;
	padding-top: 50px;
	width: 100%;
}

#nav.mobile li {
	padding: 20px 0;
	border-bottom: 1px solid #dde8f0;
}

#nav.mobile li:last-child {
	border: none;
}

#nav.mobile li p {
	color: #97a5b6;
	font-size: 15px;
	margin-bottom: 10px;
}

#nav.mobile li a {
	font-size: 18px;
	display: block;
	padding-bottom: 5px;
	font-weight: 500;
}

.btn-wrap {
	float: right;
	padding: 0;
}

.btn-wrap button {
	float: right;
	margin: 20px 0;
}

@media (max-width: 1140px) {
	.container {
		padding: 0 16px;
	}
}

@media (max-width: 1023px) {
	.container {
		padding: 20px 16px;
	}

	.logo-wrap {
		padding: 0;
	}

	.btn-menu {
		display: block;
	}
}

@media (max-width: 767px) {
	.lang-wrap {
		margin: 30px 0;
	}

	#nav {
		padding: 0;
		float: left;
		width: 100%;
	}

	#nav .nav-item a {
		padding: 0 40px 0 0;
	}
}
</style>
