import Vue from 'vue'
import VueI18n from 'vue-i18n'
import kr from '@/locales/ko-KR.json'
import us from '@/locales/en-US.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: 'kr',
	fallbackLocale: 'us',
	messages: { kr, us },
})

export default i18n
