<template>
	<div id="app">
		<HeaderArea />

		<transition name="view">
			<router-view :class="$route.name" class="contents" />
		</transition>

		<FooterArea />
	</div>
</template>

<script>
import '@/assets/css/common.css'
import '@/assets/css/button.css'
import '@/assets/css/card.css'
import '@/assets/css/icon.css'
import '@/assets/css/color.css'
import '@/assets/css/grid.css'
import '@/assets/css/table.css'
import '@/assets/css/space.css'
import '@/assets/css/element-ui.css'

import HeaderArea from '@/components/header-area'
import FooterArea from '@/components/footer-area'
export default {
	components: {
		HeaderArea,
		FooterArea,
	},
}
</script>

<style scoped></style>
