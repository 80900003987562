import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'main',
		component: () => import('@/views/main-page.vue'),
	},
	{
		path: '/reverse',
		name: 'reverse',
		alias: '/reverse',
		component: () => import('@/views/reverse-page.vue'),
	},
	{
		path: 'https://www.gachicam.app',
		name: 'gachicam',
		alias: '/gachicam',
	},
	{
		path: '/gachiga',
		name: 'gachiga',
		alias: '/gachiga',
		component: () => import('@/views/gachiga-page.vue'),
	},
	{
		path: '/showcase',
		name: 'showcase',
		component: () => import('@/views/showcase-page.vue'),
	},
	{
		path: '/showcase_detail',
		name: 'showcase_detail',
		component: () => import('@/views/showcase-detail-page.vue'),
	},
	{
		path: '/solutions-vfs',
		name: 'solutions-vfs',
		component: () => import('@/views/solutions-vfs.vue'),
	},
	{
		path: '/solutions-other',
		name: 'solutions-other',
		component: () => import('@/views/solutions-other.vue'),
	},
	{
		path: '/pricing',
		name: 'pricing',
		component: () => import('@/views/pricing-page.vue'),
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/views/about-page.vue'),
	},
	{
		path: '/notice',
		name: 'notice',
		component: () => import('@/views/notice-page.vue'),
	},
	{
		path: '/notice-detail',
		name: 'notice-detail',
		component: () => import('@/views/notice-detail.vue'),
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('@/views/contact-page.vue'),
	},
	{
		path: '/careers',
		name: 'careers',
		component: () => import('@/views/careers-page.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
