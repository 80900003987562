import VueCookies from 'vue-cookies'

// locale
export const saveLocaleCookie = locale => {
	return VueCookies.set('locale', locale)
}

export const getLocaleCookie = () => {
	return VueCookies.get('locale')
}
